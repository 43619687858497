import axios from 'axios'
import {message} from 'ant-design-vue';

const myAxios = axios.create();


// 响应时间
myAxios.defaults.timeout = 240 * 1000
// 配置请求头
myAxios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8'
// 配置接口地址
// POST传参序列化(添加请求拦截器)
myAxios.interceptors.request.use(
    config => {
        config.headers.Token = sessionStorage.getItem("Token")
        return config
    },
    err => {
        return Promise.reject(err)
    }
)
// 返回状态判断(添加响应拦截器)
myAxios.interceptors.response.use(
    response => {
        return response
    },
    error => {
        return Promise.reject(error)
    }
)

// 发送请求
export function fetchPost(url: string, params?: any) {
    return new Promise((resolve, reject) => {
        myAxios
            .post(url, params)
            .then(
                res => {
                    if (res.data.Result == "Error") {
                        message.error(res.data.Msg)
                    }
                    resolve(res.data)
                },
                err => {
                    reject(err.data)
                }
            )
            .catch(err => {
                reject(err.data)
            })
    })
}

export function fetchPut(url: string, params: any) {
    return new Promise((resolve, reject) => {
        myAxios
            .put(url, params)
            .then(
                res => {
                    resolve(res.data)
                },
                err => {
                    reject(err.data)
                }
            )
            .catch(err => {
                reject(err.data)
            })
    })
}

export function fetchDelete(url: string, params: any) {
    return new Promise((resolve, reject) => {
        myAxios
            .delete(url, {
                params: params
            })
            .then(
                res => {
                    resolve(res.data)
                },
                err => {
                    reject(err.data)
                }
            )
            .catch(err => {
                reject(err.data)
            })
    })
}

export function fetchGet(url: string, params?: any) {
    return new Promise((resolve, reject) => {
        myAxios
            .get(url, {
                params: params
            })
            .then(res => {
                if (
                    res.data.Result == "Error" &&
                    url.indexOf("/Authorize/GetAuthState") < 0
                ) {
                    message.error(res.data.Msg)
                }
                resolve(res.data)
            })
            .catch(err => {
                reject(err.data)
            })
    })
}

export function downloadFile(url: string, params?: any) {
    return new Promise((resolve, reject) => {
        myAxios({
            url,
            method: "get",
            params: params,
            responseType: 'blob'
        }).then(res => {
            if (res.data.Result == "Error") {
                message.error(res.data.Msg)
            }
            resolve(res.data)
        }).catch(err => {
            reject(err.data)
        })
    })
}

export function uploadFile(url: string, data: any) {
    return new Promise((resolve, reject) => {
        myAxios({
            url,
            method: "post",
            data: data,
            headers: {
                'Content-Type': 'application/octet-stream',
            },
        }).then(res => {
            if (res.data.Result == "Error") {
                message.error(res.data.Msg)
            }
            resolve(res.data)
        }).catch(err => {
            reject(err.data)
        })
    })
}
