import {fetchGet, fetchPut, fetchPost, fetchDelete, downloadFile, uploadFile} from '@/api/helpers_x2server.ts';

/*********************************************************************************
 * 获取log日志
 * @param data
 */
export function getPerformance() {
    return fetchGet(sessionStorage.getItem("Host") + '/Sys/GetPerformance')
}

/**
 * 获取log日志
 * @param data
 */
export function getLogApi(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/Log/Get', data)
}

/**
 * 获取log日志文件列表
 * @param data
 */
export function getLogFileList(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/Log/GetFileList', data)
}

/**
 * 下载log日志文件
 * @param data
 */
export function getLogFileStream(data: any) {
    return downloadFile(sessionStorage.getItem("Host") + '/Log/GetLogFileStream', data)
}

/*********************************************************************************
 * 获取token分页列表
 * @param data
 */
export function getTokenList(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/Token/GetList', data)
}

/**
 * 添加token
 * @param data
 */
export function addToken(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/Token/Create', data)
}

/**
 * 删除token
 * @param data
 */
export function deleteToken(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/Token/Delete', data)
}

/*********************************************************************************
 * 获取系统模型树
 * @param data
 */
export function getModuleTree(data?: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/Data/GetChild', data)
}

/**
 * 获取系统模型详细信息
 * @param data
 */
export function getModuleInfo(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/Data/GetAttr', data)
}

/*********************************************************************************
 * 获取序列号
 * @param data
 */
export function getUniqueCode() {
    return fetchGet(sessionStorage.getItem("Host") + '/Authorize/GetUniqueCode')
}

/**
 * 激活
 * @param data
 */
export function authorizeActive(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/Authorize/Active', data)
}

/**
 * 购买激活码
 * @param data
 */
export function authorizeAddActiveOrder(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/Authorize/AddActiveOrder', data)
}

/**
 * 获取激活码
 * @param data
 */
export function authorizeGetAuthCode() {
    return fetchGet(sessionStorage.getItem("Host") + '/Authorize/GetAuthCode')
}

/**
 * 获取激活码
 * @param data
 */
export function getDevLocation() {
    return fetchGet(sessionStorage.getItem("Host") + '/Location/GetDevLocation')
}

/**
 * 查询激活状态
 * @param data
 */
export function getAuthState() {
    return fetchGet(sessionStorage.getItem("Host") + '/Authorize/GetAuthState')
}

/**
 * 查询SystemKey
 * @param data
 */
export function getSystemKey() {
    return fetchGet(sessionStorage.getItem("Host") + '/Sys/GetSystemKey')
}

/*********************************************************************************
 * 获取设备form表单结构
 * @param data
 */
export function getDevConfigSchema(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/Dev/GetConfigSchema', data)
}

/**
 * 获取服务form表单结构
 * @param data
 */
export function getServiceConfigSchema(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/Service/GetConfigSchema', data)
}

/**
 * 获取设备插件列表
 * @param data
 */
export function getDevPlugin() {
    return fetchGet(sessionStorage.getItem("Host") + '/Dev/GetTypeList')
}

/**
 * 获取服务插件列表
 * @param data
 */
export function getServicePlugin() {
    return fetchGet(sessionStorage.getItem("Host") + '/Service/GetTypeList')
}

/**
 * 添加设备
 * @param data
 */
export function addDev(data: any) {
    return fetchPost(sessionStorage.getItem("Host") + '/Dev/Add', data)
}

/**
 * 添加设备
 * @param data
 */
export function updateDev(data: any) {
    return fetchPost(sessionStorage.getItem("Host") + '/Dev/SetConfig', data)
}

/**
 * 添加设备
 * @param data
 */
export function updateService(data: any) {
    return fetchPost(sessionStorage.getItem("Host") + '/Service/SetConfig', data)
}

/**
 * 添加设备
 * @param data
 */
export function addService(data: any) {
    return fetchPost(sessionStorage.getItem("Host") + '/Service/Add', data)
}

/*********************************************************************************
 * 获取设备组织树列表
 * @param data
 */
export function getDevOrgTree(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/DevOrg/GetChild', data)
}

/**
 * 获取设备分页列表
 * @param data
 */
export function addDevGroup(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/DevOrg/AddGroup', data)
}

/**
 * 获取设备分页列表
 * @param data
 */
export function updateDevGroup(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/DevOrg/Rename', data)
}

/**
 * 获取设备分页列表
 * @param data
 */
export function delDevGroup(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/DevOrg/DelGroup', data)
}

/**
 * 获取设备分页列表
 * @param data
 */
export function getDevList(data?: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/Dev/GetList', data)
}

/**
 * 获取设备分页列表
 * @param data
 */
export function getServiceList(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/Service/GetList', data)
}

/**
 * 删除设备
 * @param data
 */
export function delDev(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/Dev/Del', data)
}

/**
 * 删除设备
 * @param data
 */
export function delService(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/Service/Del', data)
}

/**
 * 禁用启用设备
 * @param data
 */
export function setDevicesEnable(data: any) {
    return fetchPost(sessionStorage.getItem("Host") + '/Dev/SetDevicesEnable', data)
}

/**
 * 禁用启用服务
 * @param data
 */
export function setServiceEnable(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/Service/SetEnable', data)
}

/**
 * 获取设备标签列表
 * @param data
 */
export function getDevTagList(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/Dev/GetTagList', data)
}

/**
 * 获取设备逻辑标签列表
 * @param data
 */
export function getDevLogicTagList(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/LogicTag/GetLogicTagList', data)
}

/**
 * 添加服务订阅标签
 * @param data
 */
export function setDevMonitorList(data: any) {
    return fetchPost(sessionStorage.getItem("Host") + '/Service/SetDevMonitorList2', data)
}

/**
 * 获取服务已订阅标签列表
 * @param data
 */
export function getDevMonitorList(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/Service/GetDevMonitorList', data)
}

/**
 * 移除服务已订阅标签
 * @param data
 */
export function delDevMonitorList(data: any) {
    return fetchPost(sessionStorage.getItem("Host") + '/Service/DelDevMonitorList2', data)
}

/**
 * 获取设备动作列表
 * @param data
 */
export function getActionList(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/Dev/GetActionList', data)
}

/**
 * 批量删除设备动作
 * @param data
 */
export function delActions(data: any) {
    return fetchPost(sessionStorage.getItem("Host") + '/Dev/DelActions', data)
}

/**
 * 批量启用禁用设备动作
 * @param data
 */
export function setActionsEnable(data: any) {
    return fetchPost(sessionStorage.getItem("Host") + '/Dev/SetActionsEnable', data)
}

/**
 * 运行设备动作
 * @param data
 */
export function doAction(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/Dev/DoAction', data)
}
/**
 * 运行设备动作
 * @param data
 */
export function doInnerAction(data: any) {
    return fetchPost(sessionStorage.getItem("Host") + '/Dev/DoInnerAction', data)
}

/**
 * 获取设备动作form表单
 * @param data
 */
export function getActionConfigSchema(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/Dev/GetActionConfigSchema', data)
}

/**
 * 添加设备动作
 * @param data
 */
export function addAction(data: any) {
    return fetchPost(sessionStorage.getItem("Host") + '/Dev/AddAction', data)
}

/**
 * 修改设备动作
 * @param data
 */
export function editAction(data: any) {
    return fetchPost(sessionStorage.getItem("Host") + '/Dev/SetActionConfig', data)
}

/**
 * 获取设备标签组织树
 * @param data
 */
export function getTagOrgTree(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/TagOrg/GetTagChild', data)
}

/**
 * 删除设备标签组织
 * @param data
 */
export function delTagGroup(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/TagOrg/DelTagGroup', data)
}

/**
 * 新增设备标签组织
 * @param data
 */
export function addTagGroup(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/TagOrg/AddTagGroup?', data)
}

/**
 * 修改设备标签组织名称
 * @param data
 */
export function tagRename(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/TagOrg/TagRename?', data)
}

/**
 * 获取设备属性标签
 * @param data
 */
export function getTagList(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/Dev/GetTagList?', data)
}
/**
 * 获取设备逻辑标签
 * @param data
 */
export function getLogicTagList(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/LogicTag/GetLogicTagList?', data)
}

/**
 * 获取设备属性标签表单信息
 * @param data
 */
export function getTagConfigSchema(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/Dev/GetTagConfigSchema?', data)
}
/**
 * 获取设备逻辑标签表单信息
 * @param data
 */
export function getLogicTagConfigSchema(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/LogicTag/GetLogicTagConfigSchema?', data)
}

/**
 * 新增设备属性标签
 * @param data
 */
export function addTag(data: any) {
    return fetchPost(sessionStorage.getItem("Host") + '/Dev/AddTag?', data)
}
/**
 * 新增设备逻辑标签
 * @param data
 */
export function addLogicTag(data: any) {
    return fetchPost(sessionStorage.getItem("Host") + '/LogicTag/AddLogicTag?', data)
}

/**
 * 修改设备属性标签
 * @param data
 */
export function editTag(data: any) {
    return fetchPost(sessionStorage.getItem("Host") + '/Dev/SetTagConfig?', data)
}
/**
 * 修改设备逻辑标签
 * @param data
 */
export function editLogicTag(data: any) {
    return fetchPost(sessionStorage.getItem("Host") + '/LogicTag/setLogicTagConfig', data)
}

/**
 * 批量删除设备属性标签
 * @param data
 */
export function delTag(data: any) {
    return fetchPost(sessionStorage.getItem("Host") + '/Dev/DelTags?', data)
}

/**
 * 批量启用禁用设备属性标签
 * @param data
 */
export function setTagsEnable(data: any) {
    return fetchPost(sessionStorage.getItem("Host") + '/Dev/SetTagsEnable?', data)
}

/**
 * 批量启用禁用设备属性标签
 * @param data
 */
export function writeTag(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/Dev/WriteTag?', data)
}

/**
 * 下载属性标签URL列表
 * @param data
 */
export function downloadUrlList(data: any) {
    return downloadFile(sessionStorage.getItem("Host") + '/Dev/GetTagURLListStream?', data)
}
/**
 * 下载逻辑标签CSV配置
 * @param data
 */
export function downloadLogicTagUrlList(data: any) {
    return downloadFile(sessionStorage.getItem("Host") + '/LogicTag/GetLogicTagURLListStream?', data)
}

/**
 * 下载属性标签CSV配置
 * @param data
 */
export function downloadTagCSVFile(data: any) {
    return downloadFile(sessionStorage.getItem("Host") + '/Dev/CreateTagCSVFileStream?', data)
}
/**
 * 下载逻辑标签CSV配置
 * @param data
 */
export function downloadLogicTagCSVFile(data: any) {
    return downloadFile(sessionStorage.getItem("Host") + '/LogicTag/CreateLogicTagCSVFileStream?', data)
}

/**
 * 导入属性标签CSV文件
 * @param data
 */
export function readTagCSVFile(id: any, data: any) {
    return uploadFile(sessionStorage.getItem("Host") + '/Dev/ReadTagCSVFileStream?DevID=' + id, data)
}
/**
 * 导入逻辑标签CSV文件
 * @param data
 */
export function readLogicTagCSVFile(id: any, data: any) {
    return uploadFile(sessionStorage.getItem("Host") + '/LogicTag/ReadLogicTagCSVFileStream?DevID=' + id, data)
}
/**
 * 清空tag
 * @param data
 */
export function delAllTags(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/Dev/DelAllTags?', data)
}
/**
 * 获取任务组织树
 * @param data
 */
export function getDataTaskOrgTree(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/DataTaskOrg/GetDataTaskChild?', data)
}
/**
 * 删除任务组织树
 * @param data
 */
export function delDataTaskGroup(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/DataTaskOrg/DelDataTaskGroup?', data)
}
/**
 * 添加任务组织树
 * @param data
 */
export function addDataTaskGroup(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/DataTaskOrg/AddDataTaskGroup?', data)
}
/**
 * 修改任务组织树
 * @param data
 */
export function editDataTaskGroup(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/DataTaskOrg/DataTaskRename?', data)
}

/**
 * 获取任务分页列表
 * @param data
 */
export function getTaskList(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/DataTask/GetList?', data)
}

/**
 * 删除任务分页列表
 * @param data
 */
export function delTaskList(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/DataTask/Del?', data)
}
/**
 * 导入任务列表
 * @param data
 */
export function addTaskFromCSVStream(data: any) {
    return uploadFile(sessionStorage.getItem("Host") + '/DataTask/AddTaskFromCSVStream', data)
}
/**
 * 下载任务列表
 * @param data
 */
export function creatTaskCSVStream() {
    return downloadFile(sessionStorage.getItem("Host") + '/DataTask/CreatTaskCSVStream')
}
/**
 * 新增任务
 * @param data
 */
export function addTask(data: any) {
    return fetchPost(sessionStorage.getItem("Host") + '/DataTask/Add',data)
}
/**
 * 新增任务
 * @param data
 */
export function editTask(data: any) {
    return fetchPost(sessionStorage.getItem("Host") + '/DataTask/SetConfig',data)
}
/**
 * 获取任务信息
 * @param data
 */
export function getTask(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/DataTask/Get?',data)
}
/**
 * 获取js脚本列表
 * @param data
 */
export function getScriptList(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/Script/GetList?',data)
}
/**
 * 运行js脚本
 * @param data
 */
export function runScript(data: any) {
    return fetchPost(sessionStorage.getItem("Host") + '/Script/Run?',data)
}
/**
 * 运行js脚本
 * @param data
 */
export function callScript(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/Script/Call?',data)
}
/**
 * 检查更新
 * @param data
 */
export function SysCheckUpdate() {
    return fetchGet(sessionStorage.getItem("Host") + '/Sys/CheckUpdate')
}
/**
 * 检查更新
 * @param data
 */
export function StartUpdate() {
    return fetchGet(sessionStorage.getItem("Host") + '/Sys/StartUpdate')
}
/**
 * 获取插件开发资料markdown
 * @param data
 */
export function GetPluginMaterial() {
    return fetchGet(sessionStorage.getItem("Host") + '/Plugin/GetPluginMaterial')
}

/**
 * 获取语法规范markdown
 * @param data
 */
export function GetScriptMaterial() {
    return fetchGet(sessionStorage.getItem("Host") + '/Script/GetScriptMaterial ')
}
/**
 * 设置设备分组
 * @param data
 */
export function addDevOrg(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/Dev/AddDevOrg',data)
}
/**
 * 删除设备分组
 * @param data
 */
export function delDevOrg(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/Dev/DeleteDevOrg',data)
}
/**
 * 克隆出虚拟设备
 * @param data
 */
export function cloneToVirtual(data: any) {
    return fetchPost(sessionStorage.getItem("Host") + '/Dev/CloneToVirtual',data)
}
/**
 * 设置tag分组
 * @param data
 */
export function addTagsToOrg(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/Dev/AddTagsToOrg',data)
}
/**
 * 删除tag分组
 * @param data
 */
export function delTagsToOrg(data: any) {
    return fetchPost(sessionStorage.getItem("Host") + '/Dev/DelTagsOrg',data)
}
/**
 * 下载配置文件
 * @param data
 */
export function getConfigData() {
    return downloadFile(sessionStorage.getItem("Host") + '/Sys/GetConfigData')
}
/**
 * 保存配置文件
 * @param data
 */
export function sysSaveConfig() {
    return fetchGet(sessionStorage.getItem("Host") + '/Sys/SaveConfig')
}
/**
 * 获取是否开启自动保存状态
 * @param data
 */
export function GetSaveState() {
    return fetchGet(sessionStorage.getItem("Host") + '/Sys/GetSaveState')
}
/**
 * 设置自动保存
 * @param data
 */
export function AutoSaveConfig(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/Sys/AutoSaveConfig',data)
}
/**
 * 获取版本信息号
 * @param data
 */
export function GetSystemVersion() {
    return fetchGet(sessionStorage.getItem("Host") + '/Sys/GetSystemVersion')
}

/*********************************************************
 * 获取数据库文件列表
 * @param data
 */
export function getHistoryFileList() {
    return fetchGet(sessionStorage.getItem("Host") + '/History/GetFileList')
}
/**
 * 获取用户分页列表
 * @param data
 */
export function getHistory(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/History/Get',data)
}


/*************************************************************
 * 获取设备插件商店列表
 * @param data
 */
export function getStoreDevList(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/Plugin/GetStoreDevList',data)
}
/**
 * 获取设备插件商店列表
 * @param data
 */
export function getStoreServiceList(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/Plugin/GetStoreServiceList',data)
}
/**
 * 获取设备插件商店列表
 * @param data
 */
export function addPluginOrder(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/Plugin/AddPluginOrder',data)
}
/**
 * 获取设备插件商店列表
 * @param data
 */
export function installPlugin(data: any) {
    return fetchGet(sessionStorage.getItem("Host") + '/Plugin/InstallPlugin',data)
}
